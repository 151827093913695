import React, { useState } from 'react'
import {
    FormControlLabel,
    LinearProgress,
    Typography,
    MenuItem,
    Link as MaterialLink
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import { TextField, CheckboxWithLabel } from 'formik-material-ui'
import CardLogin from '../../Molecules/CardLogin'
import { LanguageEnum } from '../../../enum/LanguageEnum'
import { Trans, useTranslation } from 'react-i18next'
import countries from '../../../utils/countries'
import CustomButton from '../../Atoms/CustomButton'

import { useAuth } from '../../../auth/useAuth'
import useStyles from '../../Molecules/CardLogin/useStyles'

import './style.scss'

function SignUpCard({ history }) {
    const { signup } = useAuth()
    const { t, i18n } = useTranslation()
    const classes = useStyles()
    const [agreeInformed, setAgreeInformed] = useState(null)

    const validate = (values) => {
        const errors = {}
        const required = 'validation.required'

        if (values.select === 'yourcountry') {
            errors.select = t('validation.country')
        } else {
            i18n.changeLanguage(LanguageEnum[values.select])
        }

        if (!values.firstname) {
            errors.firstname = t(required)
        }

        if (!values.lastname) {
            errors.lastname = t(required)
        }

        if (!values.email) {
            errors.email = t(required)
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = t('validation.email')
        }

        if (!values.displayname) {
            errors.displayname = t(required)
        }

        if (!values.password) {
            errors.password = t(required)
        } else if (values.password.length < 8) {
            errors.password = t('validation.minpass')
        }

        return errors
    }

    const setSinupError = (error, setFieldError) => {
        if (error.code === 'auth/email-already-in-use') {
            setFieldError('email', t('validation.email_already_registered'))
        }
    }

    const onSubmit = async (values, { setSubmitting, setFieldError }) => {
        console.log('values: ', values)
        try {
            await signup(values)
            history.push('/AccountOverview')
        } catch (error) {
            setSinupError(error, setFieldError)
            console.log('error', error)
        }
        setSubmitting(false)
    }

    return (
        <CardLogin>
            <section key="cardlogin">
                <Typography className={classes.signup_header} variant="subtitle2">
                    <Trans i18nKey="login.signup">Sign up</Trans>
                </Typography>
            </section>
            <Formik
                initialValues={{
                    select: 'yourcountry',
                    firstname: '',
                    lastname: '',
                    displayname: '',
                    email: '',
                    password: '',
                    news: false,
                    agree: false
                }}
                validate={validate}
                onSubmit={onSubmit}
            >
                {({ submitForm, isSubmitting, dirty }) => (
                    <Form id="signup__form">
                        <Field
                            component={TextField}
                            type="text"
                            name="select"
                            select
                            variant="standard"
                            margin="normal"
                            InputLabelProps={{
                                shrink: true
                            }}
                            className={classes.select}
                        >
                            <MenuItem key={'yourcountry'} value={'yourcountry'} className={classes.select}>
                                <Trans i18nKey="form.yourcountry">{t('login.yourcountry')}</Trans>
                            </MenuItem>
                            {countries.map((country) => (
                                <MenuItem key={country} value={country} className={classes.select}>
                                    {t(`countries.${country}`)}
                                </MenuItem>
                            ))}
                        </Field>
                        <div className="card__fields">
                            <Field
                                component={TextField}
                                name="firstname"
                                type="text"
                                className={classes.input}
                                placeholder={`*${t('login.firstname')}`}
                            />
                            <Field
                                component={TextField}
                                name="lastname"
                                type="text"
                                className={classes.input}
                                placeholder={`*${t('login.lastname')}`}
                            />
                        </div>
                        <Field
                            component={TextField}
                            name="displayname"
                            type="text"
                            className={classes.input}
                            placeholder={`*${t('login.displayname')}`}
                        />
                        <Field
                            component={TextField}
                            name="email"
                            type="email"
                            className={classes.input}
                            placeholder={`*${t('login.email')}`}
                        />
                        <Field
                            component={TextField}
                            type="password"
                            name="password"
                            className={classes.input}
                            placeholder={`*${t('login.password')}`}
                        />
                        <FormControlLabel
                            label={t('login.news')}
                            className={classes.checkbox}
                            control={<Field component={CheckboxWithLabel} name="news" type="checkbox" />}
                        />
                        <div className="terms">
                            <FormControlLabel
                                label={t('login.agree')}
                                name="terms"
                                className={classes.checkbox}
                                onClick={(e) => setAgreeInformed(e.target.value === 'true')}
                                control={<Field component={CheckboxWithLabel} name="agree" type="checkbox" />}
                            />
                            <a className={classes.link} href={t('privacy.terms.link')} target="_blank" rel="noopener noreferrer">
                                <MaterialLink>
                                    <Typography className={classes.typography} variant="subtitle2">
                                        <Trans i18nKey="login.terms">terms of service</Trans>
                                    </Typography>
                                </MaterialLink>
                            </a>
                        </div>
                        {isSubmitting && <LinearProgress />}
                        <CustomButton
                            disabled={isSubmitting || !dirty || agreeInformed === true || agreeInformed === null}
                            onClick={submitForm}
                            color="primary"
                            variant="contained"
                            fullWidth
                            size="large"
                        >
                            <Trans i18nKey="login.createaccount">Create Account</Trans>
                        </CustomButton>
                    </Form>
                )}
            </Formik>
            <div className={classes.signup_section}>
                <Typography className={classes.signup_label} variant="body1">
                    <Trans i18nKey="login.haveaccount">Have an account</Trans>?
                </Typography>
                <Link className={classes.signup_link} to="/">
                    <MaterialLink>
                        <Typography className={classes.signup_label} variant="body1">
                            <Trans i18nKey="login.signin">Sign in</Trans>
                        </Typography>
                    </MaterialLink>
                </Link>
            </div>
            <section key="privacypolicy" className={classes.policy_section}>
                <a className={classes.policy_link}>
                    <MaterialLink href={t('privacy.policy.link')} target="_blank" rel="noopener noreferrer">
                        <Trans i18nKey="privacy.policy.label">Privacy Policy</Trans>
                    </MaterialLink>
                </a>
            </section>
        </CardLogin>
    )
}

export default SignUpCard
